


















































































































































import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import HaciendaItemLote from "@/entidades/Plantacion/HaciendaItemLote";
import ResponseGenerico from "@/entidades/ResponseGenerico";
import Alerta from "@/entidades/Sistema/Alerta";
import Loading from "@/entidades/Sistema/Loading";
import { Vue, Component, Prop } from "vue-property-decorator";
import { State, Action } from "vuex-class";
@Component({
  name: "AgregarLoteHacienda",
  components: {
    loteAdd: () => import("@/components/Maestro/Hacienda/AgregarLote.vue"),
  },
})
export default class AgregarLoteHacienda extends Vue {
  @State("usuarioId", { namespace: "authModule" }) usuarioId!: string;
  @Prop({ type: Object, required: true }) itemLote!: HaciendaItemLote;
  @Prop({ type: Array, required: true }) haciendaItems!: HaciendaItem[];
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;

  haciendaItemLote: HaciendaItemLote = new HaciendaItemLote();
  haciendaItemId: number = 0;
  editarHacienda: boolean = false;
  mounted() {
    console.log("Usuario:", this.usuarioId);
    console.log(this.itemLote);
    if (this.itemLote != undefined) {
      this.haciendaItemId = this.itemLote.haciendaId!;
      this.haciendaItemLote = this.itemLote;
      this.editarHacienda = true;
    }
  }
  async agregarLoteDb() {
    this.itemLote.usuarioId = this.usuarioId;
    this.itemLote.haciendaItemId = this.haciendaItemId;
    console.log("vamos agregar", JSON.stringify(this.itemLote));
    console.log(this.itemLote);
    if (this.itemLote.ordenInterna!.length < 1) {
      this.changeAlerta(
        new Alerta("Debe ingresar una orden interna", true, "danger", 3000)
      );
      return;
    }
    try {
      this.changeLoading(new Loading(true, "Registrando informacion"));
      var rsp = new ResponseGenerico(false, "");
      //@ts-ignore
      if (this.itemLote.id > 0) {
        console.log("Entramos en IF de Editar");
        this.editarHacienda = true;
        this.haciendaItemLote.id = this.itemLote.haciendaLoteId;
        console.log(this.haciendaItemLote);
        rsp = await HaciendaItemLote.EditarHaciendaItemLote(
          this.haciendaItemLote
        );
      } else {
        console.log("Entramos en IF de Agregar");
        this.haciendaItemLote.id = this.haciendaItemId;
        console.log("Agregar", this.haciendaItemLote);
        rsp = await HaciendaItemLote.GuardarHaciendaItemLote(
          this.haciendaItemLote
        );
      }

      if (rsp.isSuccess == true) {
        console.log("Entramos en IF de isSuccess");
        this.changeAlerta(new Alerta(rsp.isMessage, true, "primary", 3000));
        this.$emit("close");
      } else {
        console.log("Entramos en else");
        this.changeAlerta(new Alerta(rsp.isMessage, true, "danger", 3000));
        console.log(rsp.isMessage);
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.changeLoading(new Loading(false, "Registrando informacion"));
    }
  }
}
